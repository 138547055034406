import axios from "axios";

class ExpressService {
   getIdpHintForUsername = (username: string) => {
      return axios.get('api/idphint', {
         params: { username }
      });
   }
}

export default new ExpressService();
