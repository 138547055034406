import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import logo from "../../assets/img/logo.png";
import { withStyles } from 'tss-react/mui';
import { Card, CardContent, CardActions, CardMedia, FormHelperText, ListItem, ListItemIcon, ListItemText, InputAdornment, IconButton, Link } from "@mui/material";
import { Person, ArrowBack, PasswordSharp } from "@mui/icons-material";
import Button from "../../components/Common/Button";
import CustomInput from "../../components/Common/CustomInput";
import loginStyle from "../../assets/jss/pages/loginStyle";
import { ILoginPageProps } from "../../types/propType";
import { Resources } from "../../constants/resources";
import { ILogin, IForgotPassword } from "../../types/dataTypes";
import { StatusCode } from "../../constants/constant";
import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";
import { isLogin, azureLogin, getLoggedInPortalRole } from '../../utils/LoginHelpers';
import { useIsAuthenticated } from "@azure/msal-react";
import { getHomePageForRole } from '../../utils/PermissionHelpers';
import healthService from "../../services/health.service";
import environment from "../../environment";
import ExpressService from "../../services/express.service";
import keycloak from "../../utils/keycloak/keycloak";


const Login: React.FC<ILoginPageProps> = ({ classes }) => {
   const [error, setError] = useState(null);
   const [success, setSuccess] = useState<string | null>(null);
   const [stepNumber, setStepnumber] = useState(1);
   const navigate = useNavigate();
   useEffect(() => {
      healthService.checkServerStatus().then((res: any) => {
         if (res?.status !== StatusCode.Success) {
            navigate("/undermaintenance");
         }
      });
   }, [navigate]);

   let initialValues: ILogin = {
      UserName: "",
      Password: ""
   }

   const validationSchema = [
      Yup.object().shape({
         UserName: Yup.string().trim(Resources.Error_UserName_Required).required(Resources.Error_UserName_Required).email(Resources.Error_Email),
      }),
      Yup.object().shape({
         Password: Yup.string().trim(Resources.Error_Password_Required).required(Resources.Error_Password_Required),
      })
   ];

   const currentValidationSchema = validationSchema[stepNumber - 1];

   const redirectToKeycloak = (email: string, idpHint = '') => {
      let redirectUri = window.location.origin + "/clients";
      keycloak.login({
         loginHint: email,
         idpHint,
         redirectUri: redirectUri
      });
   };

   const handleLogin = (values: ILogin) => {
      if (stepNumber === 1) {
         const email = values.UserName;

         if (Boolean(environment?.kcEnabled)) {
            ExpressService.getIdpHintForUsername(email)
               .then((res) => redirectToKeycloak(email, res.data))
               .catch(() => {
                  redirectToKeycloak(email);
               });
         }
         else if (/@guardianangelsafety.co\s*$/.test(email)) {
            azureLogin(email, navigate);
            setError(null);
            setSuccess(null);
         }
         else {
            setStepnumber(2);
            setError(null);
            setSuccess(null);
         }
      }
      if (stepNumber === 2 && values.Password) {
         AuthService.login(values).then((res: any) => {
            if (res?.status === StatusCode.Success && res?.data) {
               localStorage.setItem("token", res?.data?.token);
               localStorage.setItem("roles", JSON.stringify(res?.data?.roles));
               localStorage.setItem("refreshToken", res?.data?.refreshToken);
               localStorage.setItem("permissions", JSON.stringify(res?.data?.permissions));
               localStorage.setItem("clientName", res?.data?.clientName);
               localStorage.setItem("planName", res?.data?.planName);
               if (res?.data?.personOid)
                  localStorage.setItem("personOid", res?.data?.personOid);
               if (res?.data?.allowDeviceAndEscalationAssignmentToSelf)
                  localStorage.setItem("allowDeviceAndEscalationAssignmentToSelf", res?.data?.allowDeviceAndEscalationAssignmentToSelf);
               setSuccess("Login Done");
            } else {
               setError(res?.data?.message);
               setSuccess(null);
            }
         });
      }
   }

   const forgotPasswordClick = (values: ILogin) => {
      const params: IForgotPassword = {
         Email: values.UserName
      }
      UserService.forgotPassword(params).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            setError(null);
            setSuccess(res?.data?.message);
         } else {
            setError(res?.data?.message);
            setSuccess(null);
         }
      });
   }

   const isAzureLogin = useIsAuthenticated();
   console.log("loc 171224-1236:", isAzureLogin);
   return (
      isAzureLogin || isLogin() || keycloak.authenticated ?

         <Navigate to={getHomePageForRole(getLoggedInPortalRole(isAzureLogin))} />
         :
         <Formik initialValues={initialValues} validationSchema={currentValidationSchema} onSubmit={handleLogin} validateOnBlur={false}>
            {({ values }) => (
               <Form data-testid="loginForm" className={classes.container} noValidate>
                  <Card className={classes.card}>
                     <CardMedia component="img" image={logo} alt="Guardian Angel Safety" />
                     <CardContent>
                        {stepNumber === 1 &&
                           <CustomInput
                              labelText="Username"
                              id="UserName"
                              inputProps={{
                                 required: true,
                                 name: "UserName"
                              }}
                              InputProps={{
                                 endAdornment: (
                                    <InputAdornment position="start">
                                       <Person className={classes.inputAdornmentIcon} />
                                    </InputAdornment>
                                 )
                              }}
                              value={values.UserName}
                              autoFocus={true}
                           />
                        }
                        {stepNumber === 2 &&
                           <>
                              <ListItem style={{ paddingLeft: 0 }} >
                                 <ListItemIcon style={{ minWidth: 30 }} >
                                    <IconButton onClick={() => { setStepnumber(1); setError(null); setSuccess(null); }} sx={{ p: 0 }}>
                                       <ArrowBack />
                                    </IconButton>
                                 </ListItemIcon>
                                 <ListItemText>{values.UserName}</ListItemText>
                              </ListItem>
                              <CustomInput
                                 labelText="Password"
                                 id="Password"
                                 type="password"
                                 inputProps={{
                                    type: "password",
                                    name: "Password",
                                    required: true
                                 }}
                                 InputProps={{
                                    endAdornment: (
                                       <InputAdornment position="end">
                                          <PasswordSharp className={classes.inputAdornmentIcon} />
                                       </InputAdornment>
                                    )
                                 }}
                                 value={values.Password}
                                 autoFocus={true}
                              />
                              <Link onClick={() => forgotPasswordClick(values)} underline="none">
                                 Forgot my password
                              </Link>
                           </>
                        }
                        <FormHelperText id="error-login" error>{error}</FormHelperText>
                        <FormHelperText id="success-forgorpassword" focused>{success}</FormHelperText>
                     </CardContent>
                     <CardActions>
                        <Button type="submit" color="primary" block>{stepNumber === 1 ? "Next" : "Login"}</Button>
                     </CardActions>
                  </Card>
               </Form>
            )}
         </Formik>

   );
}
export default withStyles(Login, loginStyle);
