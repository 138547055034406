import * as React from 'react';
import { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Button, Divider, MenuItem } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import { Logout, KeyboardArrowDown, Key, PermIdentity, FiberManualRecordRounded } from '@mui/icons-material';
import authService from '../services/auth.service';
import { Link, useNavigate } from 'react-router-dom';
import { primaryColor } from '../assets/jss/appStyle';
import { StatusCode } from '../constants/constant';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import environment from '../environment';
import { getLoggedInKeycloakRole } from '../utils/LoginHelpers';
//import { useKeycloak } from '../utils/keycloak/keycloakProvider';
const StyledMenu = styled((props: MenuProps) => (
   <Menu
      elevation={0}
      anchorOrigin={{
         vertical: 'bottom',
         horizontal: 'right',
      }}
      transformOrigin={{
         vertical: 'top',
         horizontal: 'right',
      }}
      {...props}
   />
))(({ theme }) => ({
   '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
         theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
         'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
         padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
         '& .MuiSvgIcon-root': {
            fontSize: 18,
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(1.5),
         },
         '&:active': {
            backgroundColor: alpha(
               theme.palette.primary.main,
               theme.palette.action.selectedOpacity,
            ),
         },
      },
   },
}));
interface ILogInAsUser {
   userName: string;
   className: string;
   showClientApiKeys: boolean;
}
const LogInAsUser: React.FC<ILogInAsUser> = ({ userName, className, showClientApiKeys }) => {
   // const { handleBackToImpersonator } = useKeycloak();
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const open = Boolean(anchorEl);
   const roles = localStorage.getItem("roles");
   const rolesArray = Boolean(environment.kcEnabled) ? getLoggedInKeycloakRole() : roles ? JSON.parse(roles) : [];
   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };
   const navigate = useNavigate();
   const handleClose = () => {
      setAnchorEl(null);
   };
   const [openPopup, setOpen] = useState(false);
   const handleClosePopup = () => {
      setOpen(false);
   };
   const handleOpenPopup = () => {
      setOpen(true);
   };
   const handleBackToGASStaff = async () => {
      setAnchorEl(null);
      localStorage.removeItem("token");
      localStorage.removeItem("roles");
      localStorage.removeItem("permissions");
      localStorage.removeItem("userName");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("clientName");
      localStorage.removeItem("planName");
      localStorage.removeItem("allowDeviceAndEscalationAssignmentToSelf");
      localStorage.removeItem("personOid");
      localStorage.removeItem("IsApiFailing");
      if (Boolean(environment.kcEnabled)) {
         // handleBackToImpersonator();
      }
      authService.getFeaturesPermissions().then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            localStorage.setItem("permissions", JSON.stringify(res.data));
            if (window.location.pathname === "/clients") {
               window.location.reload();
            }
            else {
               navigate("/clients");
            }
         }
         else {
            localStorage.clear();
            navigate("/login");
         }
      });
   };
   const MyRolesModal = ({ roles, open, onClose }: { roles: any, open: boolean, onClose: () => void }) => {
      return (
         <Dialog open={open} onClose={onClose} scroll="paper" fullWidth maxWidth="sm">
            <DialogTitle id="scroll-dialog-title">My  Roles</DialogTitle>
            <DialogContent dividers>
               <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                     {roles.map((role: any, index: any) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                           <FiberManualRecordRounded fontSize="small" style={{ marginRight: '5px' }} />
                           <span>{' ' + role}</span>
                        </div>
                     ))}
                  </Grid>
               </Grid>
            </DialogContent>
            <DialogActions>
               <Button onClick={onClose}>Close</Button>
            </DialogActions>
         </Dialog>
      )
   }
   return (
      <div className={className}>
         <Button
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDown />}
            size="small"
            style={{ width: "120px", padding: 0, backgroundColor: "black", color: "rgb(215, 179, 39)", textTransform: 'none' }}
         >
            Logged In As {userName}
         </Button>
         <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
               'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
         >
            <MenuItem onClick={handleBackToGASStaff} disableRipple>
               <Logout />
               Back to GAS Staff
            </MenuItem>
            <MenuItem onClick={handleOpenPopup} disableRipple>
               <PermIdentity />
               My Roles
            </MenuItem>
            <MyRolesModal roles={rolesArray} open={openPopup} onClose={handleClosePopup} />
            {showClientApiKeys &&
               <>
                  <Divider />
                  <MenuItem component={Link} to="/apikeys" onClick={() => { handleClose(); }}>
                     <Key style={{ color: primaryColor[0] }} />
                     Client Api Keys
                  </MenuItem>
               </>
            }
         </StyledMenu>
      </div>
   );
}
export default LogInAsUser;
