import React from "react";
import { useNavigate } from 'react-router-dom';
import { StatusCode } from "../../../constants/constant";
import { getLoggedInPortalRole } from "../../../utils/LoginHelpers";
import { getHomePageForRole } from "../../../utils/PermissionHelpers";
import Button from "../../Common/Button";
import UserService from "./../../../services/user.service";
import LoginIcon from '@mui/icons-material/Login';
import environment from "../../../environment";
import { useKeycloak } from "../../../utils/keycloak/keycloakProvider";
interface ILogInAsButtonProps {
   userOid: string;
}
const LogInAsButton: React.FC<ILogInAsButtonProps> = ({ userOid }) => {
   const navigate = useNavigate();
   //const { exchangeToken } = useKeycloak();
   const logInAsUser = () => {
      UserService.logInAsUser(userOid).then((res: any) => {
         if (res?.status === StatusCode.Success) {
            if (Boolean(environment?.kcEnabled)) {
               //exchangeToken(res?.data?.token, res?.data?.refreshToken, res?.data?.roles);
            }
            else {
               localStorage.setItem("token", res?.data?.token);
               localStorage.setItem("roles", JSON.stringify(res?.data?.roles));
               localStorage.setItem("refreshToken", res?.data?.refreshToken);
            }
            localStorage.setItem("permissions", JSON.stringify(res?.data?.permissions));
            localStorage.setItem("userName", res?.data?.userName);
            localStorage.setItem("clientName", res?.data?.clientName);
            localStorage.setItem("planName", res?.data?.planName);
            if (res?.data?.personOid)
               localStorage.setItem("personOid", res?.data?.personOid);
            if (res?.data?.allowDeviceAndEscalationAssignmentToSelf)
               localStorage.setItem("allowDeviceAndEscalationAssignmentToSelf", res?.data?.allowDeviceAndEscalationAssignmentToSelf);
            navigate(getHomePageForRole(getLoggedInPortalRole(false)));
         } else if (res?.status === StatusCode.BadRequest) {
            //this.props.snackbarShowMessage(res?.data?.message, "error");
         }
      });
   }
   return (
      <Button color="info" justIcon={true} onClick={logInAsUser}>
         <LoginIcon />
      </Button>
   )
}

export default LogInAsButton;
