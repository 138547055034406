import React from 'react';
import accessDeniedIcon from "../assets/img/AccessDenied.png";
import { withStyles } from 'tss-react/mui';
import { Box, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import Button from '../components/Common/Button';
import unauthorizedPageStyle from '../assets/jss/pages/unauthorizedPageStyle';
import { Navigate } from 'react-router-dom';
import { isLogin, Logout } from '../utils/LoginHelpers';
import { useIsAuthenticated } from "@azure/msal-react";
import CommonAppbar from '../components/Common/CommonAppBar';
import keycloak from '../utils/keycloak/keycloak';
import environment from '../environment';
const Unauthorized: React.FC<any> = ({ classes }) => {
   const isAzureLogin = useIsAuthenticated();

   const isAuthenticated = isAzureLogin || isLogin() || keycloak.authenticated;

   // console.log(`loc 240415-1648: isAuthenticated: ${isAuthenticated}, isAzureLogin: ${isAzureLogin}, keycloakAuth: ${keycloakAuth}, environment.kcEnabled: ${environment?.kcEnabled}`);

   if (!isAuthenticated) {
      return <Navigate to="/login" />
   }
   if ((isAzureLogin || (Boolean(environment?.kcEnabled) && keycloak.authenticated)) && !localStorage.getItem("permissions")) {
      return <Navigate to="/auth.html" />
   }
   return (
      <>
         {isAuthenticated &&
            <>
               <CommonAppbar classes={classes} isAzureLogin={isAzureLogin} />
               <div className={classes.pageContent}>
                  <Card sx={{ display: { xs: 'none', md: 'flex' }, maxWidth: 650, p: 4 }}>
                     <CardMedia component="img" sx={{ width: 0.3 }} image={accessDeniedIcon} alt="Access Denied" />
                     <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                           <Typography component="div" variant="h5">
                              Access Denied
                           </Typography>
                           <Typography variant="subtitle1" color="text.secondary" component="div">
                              You are not authorized to access this application / feature.
                           </Typography>
                           <Typography variant="subtitle1" color="text.secondary" component="div">
                              Please contact your administrator.
                           </Typography>
                        </CardContent>
                        <CardActions>
                           <Button color="primary" onClick={() => Logout()}>Logout</Button>
                        </CardActions>
                     </Box>
                  </Card>
                  <Card sx={{ display: { xs: 'flex', md: 'none', flexDirection: 'column' }, maxWidth: 650, p: 4 }}>
                     <CardMedia component="img" sx={{ width: 0.3 }} image={accessDeniedIcon} alt="Access Denied" />
                     <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                           <Typography component="div" variant="h5">
                              Access Denied
                           </Typography>
                           <Typography variant="subtitle1" color="text.secondary" component="div">
                              You are not authorized to access this application. Please contact your administrator.
                           </Typography>
                        </CardContent>
                        <CardActions>
                           <Button color="primary" onClick={() => Logout()}>Logout</Button>
                        </CardActions>
                     </Box>
                  </Card>
               </div>
            </>
         }
      </>
   );
};

export default withStyles(Unauthorized, unauthorizedPageStyle);
