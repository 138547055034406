import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from "yup";

import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { IAddEditPersonProps } from '../../../types/propType';
import Button from '../../Common/Button';
import CustomInput from '../../Common/CustomInput';
import CustomSelect from '../../Common/CustomSelect';
import SearchableSelect from '../../Common/SearchableSelect';
import { getLoggedInPortalRole } from "../../../utils/LoginHelpers";
import { IGetParams, IPerson, ISelectOption, IClientSelectOption, ISearchableSelectParams } from '../../../types/dataTypes';
import { Common } from '../../../constants/common';
import { Resources } from '../../../constants/resources';
import { StatusCode } from '../../../constants/constant';
import { mapPersonObject } from "../../../utils/commonMaps";
import PersonService from '../../../services/person.service';
import ClientService from '../../../services/client.service';
import UserService from '../../../services/user.service';
import CustomCheckbox from '../../Common/CustomCheckbox';
import { useIsAuthenticated } from "@azure/msal-react";
import LeaderSearch from './LeaderSearch';
import SearchableServerSearch from '../../Common/SearchableServerSearch';
import { ConnectedFocusError } from '../../Common/FocusError';
import CustomValidationSummary from '../../Common/CustomValidationSummary';
import MultiSelect from '../../Common/MultiSelect';
import environment from '../../../environment';
import keycloak from '../../../utils/keycloak/keycloak';
import personService from '../../../services/person.service';
const AddEditPerson: React.FC<IAddEditPersonProps> = ({ open, close, successCallback, personOid, allowEdit, classes }) => {
   const [personClientRelationOptions, setPersonClientRelationOptions] = useState<ISelectOption[]>([]);
   const [portalRoles, setPortalRoles] = useState<ISelectOption[]>([]);
   const [timeZones, setTimeZones] = useState<ISelectOption[]>([]);
   const [error, setError] = useState<any>({});
   const [showAdditionalFields, setShowAdditionalFields] = useState(false);
   const [clientName, setClientName] = useState("");
   let initialValues: IPerson = {
      Firstname: "",
      Lastname: "",
      Email: "",
      PhoneNumber1: "",
      PhoneNumber2: "",
      PersonClientRelation: {
         PersonOid: Common.EmptyGuid,
         ClientOid: "",
         RelationshipToClient: "Device User",
         ClientName: null
      },
      DeviceOids: [],
      Notes: "",
      AllowPortalAccess: false,
      RoleNames: [],
      TimeZone: "",
      EmployeeID: "",
      PositionTitle: "",
      UserFunction: "",
      Area: "",
      Department: "",
      WorkAddress: "",
      EmploymentActive: false,
      PersonLeader: {
      }
   }
   const [formValues, setFormValues] = useState<IPerson>(initialValues);
   const validationSchema = Yup.object().shape({
      Firstname: Yup.string().trim(Resources.Error_PersonFirstname_Required).required(Resources.Error_PersonFirstname_Required),
      Email: Yup.string().nullable().trim().email(Resources.Error_Email).when("AllowPortalAccess", {
         is: (value: boolean) => value === true,
         then: Yup.string().trim(Resources.Error_PersonEmail_Required).required(Resources.Error_PersonEmail_Required),
      }),
      PhoneNumber1: Yup.string().trim(Resources.Error_PersonPhoneNumber_Required)
         .required(Resources.Error_PersonPhoneNumber_Required)
         .matches(Common.PhoneRegex, Common.PhoneValidationMessage)
         .min(Common.PhoneNumberLength, Common.PhoneNumberLengthValidationMessage)
         .test('LengthWithoutSpaces', Common.PhoneNumberLengthValidationMessage, val => !val || val!.replace(/\s/g, "").length >= Common.PhoneNumberLength)
      ,
      PhoneNumber2: Yup.string().nullable().trim()
         .matches(Common.PhoneRegex, Common.PhoneValidationMessage)
         .min(Common.PhoneNumberLength, Common.PhoneNumberLengthValidationMessage)
         .test('LengthWithoutSpaces', Common.PhoneNumberLengthValidationMessage, val => !val || val!.replace(/\s/g, "").length >= Common.PhoneNumberLength)
      ,
      PersonClientRelation: Yup.object({
         ClientOid: Yup.string().trim(Resources.Error_ClientNameSelection_Required).required(Resources.Error_ClientNameSelection_Required).nullable(),
         RelationshipToClient: Yup.string().trim(Resources.Error_RelationshipToClient_Required).required(Resources.Error_RelationshipToClient_Required)
      }),
      RoleNames: Yup.array().min(1).when("AllowPortalAccess", {
         is: (value: boolean) => value === true,
         then: Yup.array().min(1, Resources.Error_PortalRole_Required).required(Resources.Error_PortalRole_Required),
         otherwise: Yup.array().min(0),
      }),
      Notes: Yup.string().nullable().test("length", 'Notes length should be less than 2000 characters.', val => !val || val.toString().length <= 2000)
   });

   useEffect(() => {
      if (open && !personOid) {
         if (environment.kcEnabled) {
            if (!(roles.includes("GAS Staff"))) {
               personService.getPersonInfoByUser(keycloak.tokenParsed.oid).then((res: any) => {
                  if (res?.status === StatusCode.Success && res.data) {

                     let values = {
                        Firstname: "",
                        Lastname: "",
                        Email: "",
                        PhoneNumber1: "",
                        PhoneNumber2: "",
                        PersonClientRelation: {
                           PersonOid: res?.data?.personOid,
                           ClientOid: res?.data?.clientOid,
                           RelationshipToClient: "Device User",
                           ClientName: res?.data?.clientName
                        },
                        DeviceOids: [],
                        Notes: "",
                        AllowPortalAccess: false,
                        RoleNames: [],
                        TimeZone: "",
                        EmployeeID: "",
                        PositionTitle: "",
                        UserFunction: "",
                        Area: "",
                        Department: "",
                        WorkAddress: "",
                        EmploymentActive: false,
                        PersonLeader: {
                        }
                     };

                     setFormValues({ ...values });
                     //   setPortalRoles(res?.data.roles);
                     setClientName(res?.data?.clientName ?? "");
                  }
               });
            }
         }
      }
   }, [open]);

   useEffect(() => {
      if (personOid) {
         const getMethodParams: IGetParams = {
            oid: personOid
         };
         PersonService.getPerson(getMethodParams).then((res: any) => {
            if (res?.status === StatusCode.Success && res?.data) {
               setFormValues(mapPersonObject(res?.data));
               setShowAdditionalFields(res?.data?.showAdditionalFields ?? false);
               setClientName(res?.data?.personClientRelation?.clientName ?? "");
            }
         });
      }
      PersonService.getFixedSelectOptions().then((res: any) => {
         if (res?.status === StatusCode.Success && res.data) {
            setPersonClientRelationOptions(res?.data?.personClientRelationOptions);
            setTimeZones(res?.data?.timeZones);
         }
      });
      UserService.getPersonPortalRoleOptions().then((res: any) => {
         if (res?.status === StatusCode.Success && res.data) {
            setPortalRoles(res?.data);
         }
      });
   }, [personOid]);

   const onSubmit = (values: any, setServerError: any) => {
      PersonService.savePerson(values).then((res: any) => {
         if (res?.status === StatusCode.Success && res.data) {
            successCallback();
         } else if (res?.status === StatusCode.BadRequest) {
            if (res.data?.errors || res.data?.message) {
               setError(res.data);
            }
            else {
               setError({});
            }
            setServerError(Common.ConvertServerErrorToFormError(res.data));
         }
      });
   }
   const allowSubmit = personOid ? (allowEdit ? true : false) : true;
   const isAzureLogin = useIsAuthenticated()
   const roles = getLoggedInPortalRole(isAzureLogin);
   const getClientsSelectOptionsForPersonScreen = (params: ISearchableSelectParams, signal: any) => {
      return ClientService.getClientsSelectOptionsForPersonScreen(params, signal);
   }
   const readOnlyAdditionalFields = localStorage.getItem("clientName") === "Suncorp";
   return (
      <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="md">
         <Formik enableReinitialize={true} initialValues={formValues} validationSchema={validationSchema} onSubmit={(values, { setErrors: setServerError }) => onSubmit(values, setServerError)}>
            {({ values, setFieldValue, setFieldTouched, handleChange, errors: formikErrors }) => (
               <Form noValidate>
                  <DialogTitle id="scroll-dialog-title">{personOid ? (allowEdit ? "Edit" : "") : "Add"} Person</DialogTitle>
                  <DialogContent dividers>
                     <ConnectedFocusError />
                     <fieldset className={!allowSubmit ? "disabled" : ""} disabled={!allowSubmit}>
                        <input type="hidden" name="Oid" value={values.Oid} />
                        <input type="hidden" name="DeviceOids" value={values.DeviceOids} />
                        <Grid container spacing={2}>
                           {roles.includes("GAS Staff") && !Common.stringNullOrEmpty(values.Oid) &&
                              <Grid item xs={12} md={6}>
                                 <CustomInput
                                    labelText="Person OID"
                                    id="Oid"
                                    inputProps={{
                                       required: false,
                                       disabled: true,
                                       name: "Oid",
                                       InputLabelProps: { shrink: true }
                                    }}
                                    value={values.Oid}
                                 />
                              </Grid>
                           }
                           <Grid item xs={12} md={6}>
                              <CustomInput
                                 labelText="First Name"
                                 id="Firstname"
                                 inputProps={{
                                    required: true,
                                    name: "Firstname"
                                 }}
                                 value={values.Firstname}
                              />
                           </Grid>
                           <Grid item xs={12} md={6}>
                              <CustomInput
                                 labelText="Last Name"
                                 id="Lastname"
                                 inputProps={{
                                    name: "Lastname"
                                 }}
                                 value={values.Lastname}
                              />
                           </Grid>
                           <Grid item xs={12} md={6}>
                              <CustomInput
                                 labelText="Email"
                                 id="email"
                                 inputProps={{
                                    required: values.AllowPortalAccess,
                                    name: "Email"
                                 }}
                                 customErrorMessage={error["email"]?.message}
                                 value={values.Email}
                              />
                           </Grid>
                           <Grid item xs={12} md={6}>
                              <CustomInput
                                 labelText="Phone number"
                                 id="phoneNumber1"
                                 inputProps={{
                                    required: true,
                                    name: "PhoneNumber1"
                                 }}
                                 value={values.PhoneNumber1}
                              />
                           </Grid>
                           <Grid item xs={12} md={6}>
                              <CustomInput
                                 labelText="Alternate phone number"
                                 id="phoneNumber2"
                                 inputProps={{
                                    name: "PhoneNumber2"
                                 }}
                                 value={values.PhoneNumber2}
                              />
                           </Grid>
                           <Grid item xs={12} md={6}>
                              <CustomSelect
                                 labelText="Relationship To Client"
                                 id="PersonClientRelation.RelationshipToClient"
                                 inputProps={{
                                    required: true,
                                    name: "PersonClientRelation.RelationshipToClient",
                                    onChange: (e: any) => {
                                       handleChange(e);
                                    }
                                 }}
                                 value={values.PersonClientRelation?.RelationshipToClient}
                                 customErrorMessage={error["relationshipToClient"]?.message}
                                 options={personClientRelationOptions}
                              />
                           </Grid>
                           <Grid item xs={12} md={6}>
                              <SearchableServerSearch
                                 labelText="Select Client Name"
                                 id="PersonClientRelation.ClientOid"
                                 inputProps={{
                                    required: true,
                                    name: "PersonClientRelation.ClientOid",
                                    disabled: values.DeviceOids && values.DeviceOids.length > 0 ? true : false,
                                 }}
                                 value={values.PersonClientRelation?.ClientOid}
                                 onSearchTextChange={getClientsSelectOptionsForPersonScreen}
                                 setValue={(value: IClientSelectOption) => {
                                    if (values.PersonClientRelation?.ClientOid !== value?.value) {
                                       setFieldValue("PersonLeader", {});
                                    }
                                    setFieldValue("PersonClientRelation.ClientOid", value?.value);
                                    setShowAdditionalFields(value?.showAdditionalFields === true);
                                    setClientName(value?.label);
                                 }}
                                 setTouched={() => {
                                    setFieldTouched("PersonClientRelation.ClientOid", true);
                                 }}
                                 isClientSelection={true}
                              />
                           </Grid>
                           <Grid item xs={12} md={6}>
                              <SearchableSelect
                                 labelText="Time Zone"
                                 id="TimeZone"
                                 inputProps={{
                                    required: false,
                                    name: "TimeZone"
                                 }}
                                 value={values.TimeZone}
                                 options={timeZones}
                                 setValue={(value: ISelectOption) => {
                                    setFieldValue("TimeZone", value?.value);
                                 }}
                                 setTouched={() => {
                                    setFieldTouched("TimeZone", true);
                                 }}
                              />
                           </Grid>
                           <Grid item xs={12} md={6}>
                              <CustomCheckbox
                                 labelText={"Allow Portal Access"}
                                 id="AllowPortalAccess"
                                 inputProps={{
                                    required: true,
                                    name: "AllowPortalAccess"
                                 }}
                                 checked={values.AllowPortalAccess}
                              />
                           </Grid>
                           {values.AllowPortalAccess &&
                              <Grid item xs={12} md={6}>
                                 <MultiSelect
                                    name="RoleNames"
                                    label="PortalRole"
                                    fullWidth
                                    options={portalRoles}
                                    value={values.RoleNames}
                                 />
                              </Grid>
                           }
                           <Grid item xs={12} md={6}>
                              <CustomInput
                                 labelText="Notes"
                                 id="notes"
                                 inputProps={{
                                    required: false,
                                    name: "Notes"
                                 }}
                                 rows={1}
                                 value={values.Notes}
                              />
                           </Grid>
                           {showAdditionalFields &&
                              <>
                                 <Grid item xs={12} md={6}>
                                    <CustomInput
                                       labelText="User Employee ID"
                                       id="EmployeeID"
                                       inputProps={{
                                          name: "EmployeeID",
                                          disabled: readOnlyAdditionalFields
                                       }}
                                       value={values.EmployeeID}
                                    />
                                 </Grid>
                                 <Grid item xs={12} md={6}>
                                    <CustomInput
                                       labelText="User Position Title"
                                       id="PositionTitle"
                                       inputProps={{
                                          name: "PositionTitle",
                                          disabled: readOnlyAdditionalFields
                                       }}
                                       value={values.PositionTitle}
                                    />
                                 </Grid>
                                 <Grid item xs={12} md={6}>
                                    <CustomInput
                                       labelText="User Function"
                                       id="UserFunction"
                                       inputProps={{
                                          name: "UserFunction",
                                          disabled: readOnlyAdditionalFields
                                       }}
                                       value={values.UserFunction}
                                    />
                                 </Grid>
                                 <Grid item xs={12} md={6}>
                                    <CustomInput
                                       labelText="User Area"
                                       id="Area"
                                       inputProps={{
                                          name: "Area",
                                          disabled: readOnlyAdditionalFields
                                       }}
                                       value={values.Area}
                                    />
                                 </Grid>
                                 <Grid item xs={12} md={6}>
                                    <CustomInput
                                       labelText="User Department"
                                       id="Department"
                                       inputProps={{
                                          name: "Department",
                                          disabled: readOnlyAdditionalFields
                                       }}
                                       value={values.Department}
                                    />
                                 </Grid>
                                 <Grid item xs={12} md={6}>
                                    <CustomInput
                                       labelText="User Work Address"
                                       id="WorkAddress"
                                       inputProps={{
                                          name: "WorkAddress",
                                          disabled: readOnlyAdditionalFields
                                       }}
                                       value={values.WorkAddress}
                                    />
                                 </Grid>
                                 <Grid item xs={12} md={6}>
                                    <CustomCheckbox
                                       labelText={"User Employment Active"}
                                       id="EmploymentActive"
                                       inputProps={{
                                          name: "EmploymentActive",
                                          disabled: ((!Common.stringNullOrEmpty(values.Oid) && clientName === "Suncorp") === true) || readOnlyAdditionalFields
                                       }}
                                       checked={values.EmploymentActive}
                                    />
                                 </Grid>
                                 <Grid item xs={12} md={6}>
                                    <LeaderSearch
                                       clientOid={values.PersonClientRelation?.ClientOid}
                                       classes={classes}
                                       setValue={(value: any) => {
                                          setFieldValue("PersonLeader", value);
                                       }}
                                       value={values.PersonLeader}
                                       disabled={readOnlyAdditionalFields}
                                    />
                                 </Grid>
                                 <Grid item xs={12} md={6}>
                                    <CustomInput
                                       labelText="Leader Employee ID"
                                       id="PersonLeader.EmployeeID"
                                       inputProps={{
                                          name: "PersonLeader.EmployeeID",
                                          disabled: true,
                                          InputLabelProps: { shrink: values.PersonLeader?.EmployeeID ? true : false }
                                       }}
                                       value={values.PersonLeader?.EmployeeID}
                                    />
                                 </Grid>
                                 <Grid item xs={12} md={6}>
                                    <CustomInput
                                       labelText="Leader First Name"
                                       id="PersonLeader.FirstName"
                                       inputProps={{
                                          name: "PersonLeader.FirstName",
                                          disabled: true,
                                          InputLabelProps: { shrink: values.PersonLeader?.FirstName ? true : false }
                                       }}
                                       customErrorMessage={error["personLeader"]?.message}
                                       value={values.PersonLeader?.FirstName}
                                    />
                                 </Grid>
                                 <Grid item xs={12} md={6}>
                                    <CustomInput
                                       labelText="Leader Last Name"
                                       id="PersonLeader.LastName"
                                       inputProps={{
                                          name: "PersonLeader.LastName",
                                          disabled: true,
                                          InputLabelProps: { shrink: values.PersonLeader?.LastName ? true : false }
                                       }}
                                       customErrorMessage={error["personLeader"]?.message}
                                       value={values.PersonLeader?.LastName}
                                    />
                                 </Grid>
                                 <Grid item xs={12} md={6}>
                                    <CustomInput
                                       labelText="Leader Email"
                                       id="PersonLeader.Email"
                                       inputProps={{
                                          name: "PersonLeader.Email",
                                          disabled: true,
                                          InputLabelProps: { shrink: values.PersonLeader?.Email ? true : false }
                                       }}
                                       value={values.PersonLeader?.Email}
                                    />
                                 </Grid>
                                 <Grid item xs={12} md={6}>
                                    <CustomInput
                                       labelText="Leader Work Mobile"
                                       id="LeaderMobile"
                                       inputProps={{
                                          name: "PersonLeader.PhoneNumber1",
                                          disabled: true,
                                          InputLabelProps: { shrink: values.PersonLeader?.PhoneNumber1 ? true : false }
                                       }}
                                       value={values.PersonLeader?.PhoneNumber1}
                                    />
                                 </Grid>
                                 <Grid item xs={12} md={6}>
                                    <CustomInput
                                       labelText="Leader Work Address"
                                       id="LeaderAddress"
                                       inputProps={{
                                          name: "PersonLeader.WorkAddress",
                                          disabled: true,
                                          InputLabelProps: { shrink: values.PersonLeader?.WorkAddress ? true : false }
                                       }}
                                       value={values.PersonLeader?.WorkAddress}
                                    />
                                 </Grid>
                              </>
                           }
                           <CustomValidationSummary serverErrors={error} formikErrors={formikErrors} />
                        </Grid>
                     </fieldset>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={close}>Cancel</Button>
                     {
                        allowSubmit &&
                        <Button color="primary" type="submit">Save</Button>
                     }
                  </DialogActions>
               </Form>
            )}
         </Formik>
      </Dialog>
   );
};

export default AddEditPerson;
