import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import logo from "../../assets/img/logo.png";
import { withStyles } from 'tss-react/mui';
import { Card, CardContent, CardActions, CardMedia, FormHelperText, Typography, CardHeader, InputAdornment } from "@mui/material";
import { EmailSharp, PasswordSharp, LockSharp } from "@mui/icons-material";

import Button from "../../components/Common/Button";
import CustomInput from "../../components/Common/CustomInput";
import loginStyle from "../../assets/jss/pages/loginStyle";

import { IResetPasswordProps } from "../../types/propType";
import { Resources } from "../../constants/resources";
import { Common } from "../../constants/common";
import { IResetPassword } from "../../types/dataTypes";
import { StatusCode } from "../../constants/constant";
import AuthService from "../../services/auth.service";
import { isLogin } from '../../utils/LoginHelpers';
import { useIsAuthenticated } from "@azure/msal-react";
import NavigationLink from "../../components/Common/NavigationLink";
import { primaryColor } from '../../assets/jss/appStyle';
import keycloak from '../../utils/keycloak/keycloak';
const ResetPassword: React.FC<IResetPasswordProps> = ({ classes }) => {
   const [pageError, setPageError] = useState<string | null>(null);
   const [error, setError] = useState(null);
   const [success, setSuccess] = useState<string | null>(null);
   const [email, setEmail] = useState<string | null>(null);
   const [userOid, setUserOid] = useState<string | null>(null);
   const pathName = window.location.pathname.split('/');
   const LinkType = pathName[1].toLowerCase() === "resetpassword" ? "ForgotPassword" : "SetPassword";
   const LinkId = pathName[2];
   let initialValues: IResetPassword = {
      ConfirmPassword: "",
      Password: "",
      LinkId: LinkId,
      Email: "",
      UserOid: "",
      LinkType: LinkType,
   }
   const validationSchema = Yup.object().shape({
      Password: Yup.string().trim(Resources.Error_Password_Required).required(Resources.Error_Password_Required).min(6, Resources.Error_PasswordLengthValidation_Message),
      ConfirmPassword: Yup.string().trim(Resources.Error_ConfirmPassword_Required).required(Resources.Error_ConfirmPassword_Required).oneOf([Yup.ref('Password'), null], Resources.Error_NewPassword_ConfirmPassword_NotMatching),
   });
   useEffect(() => {
      if (!(Common.ValidGuidRegex.test(LinkId))) {
         setPageError("This link is not valid.");
         return;
      }
      AuthService.getLinkDetail({ LinkType: LinkType, LinkId: LinkId }).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            setEmail(res?.data?.email);
            setUserOid(res?.data?.userOid);
         } else {
            setPageError(res?.data?.message);
         }
      });
   }, [LinkId, LinkType]);
   const handleSubmit = (values: IResetPassword) => {
      values.Email = email ? email : "";
      values.UserOid = userOid ? userOid : "";
      AuthService.resetPassword(values).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            setSuccess(`Password ${LinkType === "ForgotPassword" ? "reset" : "set"} successfully.`);
         } else {
            setError(res?.data?.message);
            setSuccess(null);
         }
      });
   }
   return (
      useIsAuthenticated() || isLogin() || keycloak.authenticated ?
         <Navigate to="/clients" />
         :
         <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ values }) => (
               <Form data-testid="loginForm" className={classes.container} noValidate>
                  <input type="hidden" name="LinkId" value={values.LinkId} />
                  <input type="hidden" name="LinkType" value={values.LinkType} />
                  <Card className={classes.card}>
                     <CardMedia component="img" image={logo} alt="Guardian Angel Safety" />
                     <CardHeader title={
                        <>

                           <Typography variant="h5" style={{ textAlign: "center" }} component="h2" className={classes.tileHeader}>
                              <LockSharp style={{ verticalAlign: "text-bottom" }} /> {LinkType === "ForgotPassword" ? "Reset" : "Set Account"} Password
                           </Typography>
                        </>
                     }
                     />
                     {pageError &&
                        <CardContent>
                           <FormHelperText style={{ fontSize: "medium" }} id="page-error-login" error>{pageError}</FormHelperText>
                        </CardContent>
                     }
                     {success &&
                        <CardContent>
                           <FormHelperText style={{ fontSize: "medium", color: primaryColor[0] }} id="page-error-login">{success}</FormHelperText>
                        </CardContent>
                     }
                     {!pageError && !success &&
                        <CardContent>
                           <Typography variant="subtitle1" gutterBottom component="div">
                              <EmailSharp style={{ verticalAlign: "top" }} /> {email}
                           </Typography>
                           <CustomInput
                              labelText="New Password"
                              id="Password"
                              type="password"
                              inputProps={{
                                 type: "password",
                                 name: "Password",
                                 required: true,
                              }}
                              InputProps={{
                                 endAdornment: (
                                    <InputAdornment position="end">
                                       <PasswordSharp className={classes.inputAdornmentIcon} />
                                    </InputAdornment>
                                 )
                              }}
                              value={values.Password}
                           />
                           <CustomInput
                              labelText="Confirm Password"
                              id="ConfirmPassword"
                              type="password"
                              inputProps={{
                                 type: "password",
                                 name: "ConfirmPassword",
                                 required: true,
                              }}
                              InputProps={{
                                 endAdornment: (
                                    <InputAdornment position="end">
                                       <PasswordSharp className={classes.inputAdornmentIcon} />
                                    </InputAdornment>
                                 )
                              }}
                              value={values.Password}
                           />

                           <FormHelperText id="error-login" error>{error}</FormHelperText>
                           <FormHelperText id="success-forgorpassword" focused>{success}</FormHelperText>
                        </CardContent>
                     }
                     {!pageError && !success &&
                        <CardActions>
                           <Button type="submit" color="primary" block>{LinkType === "ForgotPassword" ? "Reset" : "Set account"} password</Button>
                        </CardActions>
                     }
                     <CardActions>
                        <NavigationLink to="/login">
                           <Button type="submit" block>Return to login</Button>
                        </NavigationLink>
                     </CardActions>
                  </Card>
               </Form>
            )}
         </Formik>
   );
}
export default withStyles(ResetPassword, loginStyle);
