import React from 'react';
import accessDeniedIcon from "../assets/img/AccessDenied.png";
import { withStyles } from 'tss-react/mui';
import { Box, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import Button from '../components/Common/Button';
import unauthorizedPageStyle from '../assets/jss/pages/unauthorizedPageStyle';
import NavigationLink from '../components/Common/NavigationLink';
import CommonAppbar from '../components/Common/CommonAppBar';
import { Navigate } from 'react-router-dom';
import { isLogin } from '../utils/LoginHelpers';
import { useIsAuthenticated } from "@azure/msal-react";
import keycloak from '../utils/keycloak/keycloak';
const NotFound404: React.FC<any> = ({ classes }) => {
   const isAzureLogin = useIsAuthenticated();
   const isAuthenticated = isAzureLogin || isLogin() || keycloak.authenticated;
   if (!isAuthenticated)
      return <Navigate to="/login" />
   return (
      <>
         <CommonAppbar classes={classes} isAzureLogin={isAzureLogin} />
         <div className={classes.pageContent}>
            <Card sx={{ display: { xs: 'none', md: 'flex' }, maxWidth: 650, p: 4 }}>
               <CardMedia component="img" sx={{ width: 0.3 }} image={accessDeniedIcon} alt="404 Not Found" />
               <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                     <Typography variant="h5" style={{ paddingBottom: "0px" }} component="h2" className={classes.tileHeader}>
                        404 Not Found
                     </Typography>
                     <Typography variant="subtitle1" color="text.secondary" component="div">
                        The page you are looking for does not exist or removed.
                     </Typography>
                     <Typography variant="subtitle2" color="text.secondary" component="div">
                        But you can click the button below to go back to the homepage.
                     </Typography>
                  </CardContent>
                  <CardActions>
                     <NavigationLink to={-1}>
                        <Button color="primary">Back</Button>
                     </NavigationLink>
                  </CardActions>
               </Box>
            </Card>
            <Card sx={{ display: { xs: 'flex', md: 'none', flexDirection: 'column' }, maxWidth: 650, p: 4 }}>
               <CardMedia component="img" sx={{ width: 0.3 }} image={accessDeniedIcon} alt="404 Not Found" />
               <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                     <Typography variant="h5" style={{ paddingBottom: "0px" }} component="h2" className={classes.tileHeader}>
                        404 Not Found
                     </Typography>
                     <Typography variant="subtitle1" color="text.secondary" component="div">
                        The page you are looking for does not exist or removed.
                     </Typography>
                     <Typography variant="subtitle2" color="text.secondary" component="div">
                        But you can click the button below to go back to the homepage.
                     </Typography>
                  </CardContent>
                  <CardActions>
                     <NavigationLink to={-1}>
                        <Button color="primary">Back</Button>
                     </NavigationLink>
                  </CardActions>
               </Box>
            </Card>
         </div>
      </>
   );
};

export default withStyles(NotFound404, unauthorizedPageStyle);
