import { createRoot } from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import { createTheme, ThemeProvider } from '@mui/material';
import { primaryColor, blackColor, whiteColor, controlColor, controErrorlColor } from './assets/jss/appStyle';
import environment from './environment';
import App22 from './App22';
const theme = createTheme({
   palette: {
      primary: {
         main: primaryColor[0],
      },
   },
   components: {
      MuiListItemButton: {
         styleOverrides: {
            root: {
               "&.Mui-selected": {
                  borderLeft: `5px solid ${primaryColor[0]}`
               },
               "": {
                  margin: 0
               }
            }
         }
      },
      MuiAppBar: {
         styleOverrides: {
            root: {
               backgroundColor: blackColor,
               boxShadow: "0 10px 30px rgba(0, 0, 0, 0.14)",
               flexGrow: 1,
               color: whiteColor
            }
         }
      },
      MuiTextField: {
         styleOverrides: {
            root: {
               '& .MuiInput-underline:after': {
                  borderBottomColor: controlColor
               },
            }
         }
      },
      MuiFormLabel: {
         styleOverrides: {
            root: {
               "&.Mui-focused": {
                  color: controlColor
               },
               "&.Mui-focused.Mui-error": {
                  color: controErrorlColor
               },
            }
         }
      },
      MuiCheckbox: {
         styleOverrides: {
            root: {
               color: controlColor,
               "&.Mui-checked": {
                  color: controlColor,
               }
            }
         }
      },
      MuiFormHelperText: {
         styleOverrides: {
            root: {
               marginLeft: 0,
               marginRight: 0,
               color: controlColor,
               "&.Mui-disabled": {
                  color: controlColor
               },
            }
         }
      },
      MuiLink: {
         styleOverrides: {
            root: {
               color: controlColor,
               textDecoration: "none",
               "&:hover": {
                  textDecoration: "underline",
                  cursor: "pointer"
               },
            }
         }
      },
      MuiRadio: {
         styleOverrides: {
            root: {
               color: controlColor,
               "&.Mui-focused": {
                  color: controlColor
               },
               "&.Mui-focused.Mui-error": {
                  color: controErrorlColor
               },
               "&.Mui-checked": {
                  color: controlColor,
               }
            }
         }
      },
   }
});
const currentEnv = environment.environment ?? "";
const isDevelopmentEnvironment = currentEnv !== "PROD";
document.body.style.borderTop = isDevelopmentEnvironment ? "4mm ridge rgba(255, 165, 0, .6)" : "none";
const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
   <ThemeProvider theme={theme}>
      <BrowserRouter>
         {isDevelopmentEnvironment &&
            <header>
               <div className="marquee">
                  <p>Development Environment</p>
               </div>
            </header>
         }
         <div className='viewport'>
            <App />
         </div>
         <footer className='footer'>
            <span>Frontend: {environment?.versionFrontend} Backend: {environment?.versionBackend}</span>
         </footer>
      </BrowserRouter>
   </ThemeProvider >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
