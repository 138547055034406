import * as React from 'react';
import { useIsAuthenticated } from "@azure/msal-react";
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, MenuItem, Divider } from '@mui/material';
import { MenuOutlined, ExitToAppRounded, Lock, ExitToApp, AccountCircle, Key, Accessibility } from "@mui/icons-material";
import { withStyles } from 'tss-react/mui';
import headerStyle from '../assets/jss/layouts/headerStyle';
import { primaryColor } from '../assets/jss/appStyle';
import { Pages, StatusCode } from '../constants/constant';
import { IHeaderProps } from '../types/propType';
import { isLogin, Logout } from '../utils/LoginHelpers';
import { getMenuPermissions } from '../utils/PermissionHelpers';
import StyledMenu from "../components/Common/StyledMenu";
import HeaderMenu from "../components/Common/HeaderMenu";
import HeaderLogo from "../components/Common/HeaderLogo";
import ChangePassword from '../components/pages/Users/ChangePassword';
import LogInAsUser from './LogInAsUser';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { Common } from '../constants/common';
import WarningSign from '../components/Common/WarningSign';
import healthService from '../services/health.service';
import { useEffect } from 'react';
import keycloak from '../utils/keycloak/keycloak';
import environment from '../environment';
const Header: React.FC<IHeaderProps> = ({ classes }) => {
   const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

   const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElNav(event.currentTarget);
   };

   const handleCloseNavMenu = () => {
      setAnchorElNav(null);
   };
   const [showChangePassword, setShowChangePassword] = React.useState<boolean>(false);
   const isAzureLogin = useIsAuthenticated();
   const isAuthenticated = isAzureLogin || isLogin() || keycloak.authenticated;
   const menuPermissions = getMenuPermissions();
   const otherUserName = localStorage.getItem("userName");
   const roles = Common.GetRolesAsString();
   const planName = localStorage.getItem("planName");
   const [isWialonWebhookHealthy, setIsWialonWebhookHealthy] = React.useState<boolean>(true);
   const isGasStaff = !Common.IsArrayContainRoleWithClient();

   const [isApiFailing, setIsApiFailing] = React.useState(false);
   useEffect(() => {
      const checkApiHealth = async () => {
         healthService.CheckMonitoringStationApi().then((res: any) => {
            if (res?.status === StatusCode.Success) {
               if (res?.data === true) {
                  setIsApiFailing(false);
                  localStorage.removeItem("IsApiFailing");
               } else {
                  setIsApiFailing(true);
                  localStorage.setItem("IsApiFailing", "true");
               }
            }
         });

         if (!isGasStaff)
            return;

         healthService.CheckWialonWebhookHealth().then((res: any) => {
            const isWialonActive = (res.status == 200 || res.status == 204) && !res.data.errorMessageIfAny;
            if (!isWialonActive) {
               localStorage.setItem("isWialonUnHealthy", "true");
            }
            else {
               localStorage.removeItem("isWialonUnHealthy");
            }
            setIsWialonWebhookHealthy(isWialonActive);
         });
      };

      const intervalId = setInterval(checkApiHealth, 120000);
      return () => clearInterval(intervalId);
   }, []);
   const navigation = useNavigate();
   return (
      <>
         {isAuthenticated &&
            <>
               <AppBar position="static" style={{ backgroundColor: "black", color: "white" }} className={classes.appBar}>
                  <Container maxWidth="xl">
                     <Toolbar disableGutters>
                        <HeaderLogo isAzureLogin={isAzureLogin} />
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                           <IconButton size="large" onClick={handleOpenNavMenu} color="inherit">
                              <MenuOutlined />
                           </IconButton>
                           <Menu
                              id="menu-appbar"
                              anchorEl={anchorElNav}
                              anchorOrigin={{
                                 vertical: 'bottom',
                                 horizontal: 'left',
                              }}
                              keepMounted
                              transformOrigin={{
                                 vertical: 'top',
                                 horizontal: 'left',
                              }}
                              open={Boolean(anchorElNav)}
                              onClose={handleCloseNavMenu}
                              sx={{
                                 display: { xs: 'block', md: 'none' },
                              }}
                           >
                              {(menuPermissions[Pages.Clients] && menuPermissions[Pages.Devices] && menuPermissions[Pages.Persons]) &&
                                 <MenuItem key={Pages.Search} component={Link} to="/search" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{Pages.Search}</Typography>
                                 </MenuItem>
                              }
                              {(menuPermissions[Pages.Clients]) &&
                                 <MenuItem key={Pages.Clients} component={Link} to="/clients" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{Pages.Clients}s</Typography>
                                 </MenuItem>
                              }
                              {(menuPermissions[Pages.Devices]) &&
                                 <MenuItem key={Pages.Devices} component={Link} to="/devices" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{Pages.Devices}s</Typography>
                                 </MenuItem>
                              }
                              {(menuPermissions[Pages.Persons]) &&
                                 <MenuItem key={Pages.Persons} component={Link} to="/persons" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{Pages.Persons}s</Typography>
                                 </MenuItem>
                              }
                              {(menuPermissions[Pages.Alerts]) &&
                                 <MenuItem key={Pages.Alerts} component={Link} to="/alerts" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{Pages.Alerts}s</Typography>
                                 </MenuItem>
                              }
                              {(menuPermissions[Pages.MonitoringStation]) &&
                                 <MenuItem key={Pages.MonitoringStation} component={Link} to="/monitoringstation" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">Monitoring Stations</Typography>
                                 </MenuItem>
                              }
                              {(!menuPermissions[Pages.MonitoringStation] && menuPermissions[Pages.Users]) &&
                                 <MenuItem key={Pages.MonitoringStation} component={Link} to="/users" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">Users</Typography>
                                 </MenuItem>
                              }
                              {(menuPermissions[Pages.Locations]) &&
                                 <MenuItem key={Pages.Locations} component={Link} to="/triplocations" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">Trip Locations</Typography>
                                 </MenuItem>
                              }
                              {(menuPermissions[Pages.Trips]) &&
                                 <MenuItem key={Pages.Trips} component={Link} to="/trips" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">Trips</Typography>
                                 </MenuItem>
                              }
                              {(menuPermissions[Pages.HazardLocations]) &&
                                 <MenuItem key={Pages.HazardLocations} component={Link} to="/hazardlocations" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">Hazard Locations</Typography>
                                 </MenuItem>
                              }
                              {(menuPermissions[Pages.EscalationReport]) &&
                                 <MenuItem key={Pages.EscalationReport} component={Link} to="/escalationreport" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">Escalation Report</Typography>
                                 </MenuItem>
                              }
                              {(menuPermissions[Pages.DevicePersonReport]) &&
                                 <MenuItem key={Pages.DevicePersonReport} component={Link} to="/devicepersonreport" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">Device Person Report</Typography>
                                 </MenuItem>
                              }
                              {(menuPermissions[Pages.DeviceTestReport]) &&
                                 <MenuItem key={Pages.DeviceTestReport} component={Link} to="/devicetests" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">Device Test Report</Typography>
                                 </MenuItem>
                              }
                              {(menuPermissions[Pages.SftpImportLogs]) &&
                                 <MenuItem key={Pages.SftpImportLogs} component={Link} to="/SftpImportLog" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">SFTP Import Log</Typography>
                                 </MenuItem>
                              }
                              {(menuPermissions[Pages.UserSupport]) &&
                                 <MenuItem component={Link} to="/usersupport" color="transparent" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">User Support</Typography>
                                 </MenuItem>
                              }
                              {(menuPermissions[Pages.ErrorLog]) &&
                                 <MenuItem component={Link} to="/errorlog" color="transparent" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">Error Logs</Typography>
                                 </MenuItem>
                              }
                              {(menuPermissions[Pages.SftpMonitoringLogs]) &&
                                 <MenuItem key={Pages.SftpMonitoringLogs} component={Link} to="/SftpMonitoringLog" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">SFTP Monitoring Log</Typography>
                                 </MenuItem>
                              }
                           </Menu>
                        </Box>
                        <div className={classes.menubar}>
                           <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                              <HeaderMenu label="Organisation" navigate={navigation}>
                                 {(menuPermissions[Pages.Clients] && menuPermissions[Pages.Devices] && menuPermissions[Pages.Persons]) &&
                                    <MenuItem id="/search">
                                       {Pages.Search}
                                    </MenuItem>
                                 }
                                 {(menuPermissions[Pages.Clients]) &&
                                    <MenuItem id="/clients">
                                       {Pages.Clients}s
                                    </MenuItem>
                                 }
                                 {(menuPermissions[Pages.Devices]) &&
                                    <MenuItem id="/devices">
                                       {Pages.Devices}s
                                    </MenuItem>
                                 }
                                 {(menuPermissions[Pages.Persons]) &&
                                    <MenuItem id="/persons">
                                       {Pages.Persons}s
                                    </MenuItem>
                                 }
                              </HeaderMenu>
                              <HeaderMenu label="Monitoring" navigate={navigation}>
                                 {(menuPermissions[Pages.Alerts]) &&
                                    <MenuItem id="/alerts" color="transparent">
                                       {Pages.Alerts}s
                                    </MenuItem>
                                 }
                                 {(menuPermissions[Pages.MonitoringStation]) &&
                                    <MenuItem id="/monitoringstation" color="transparent">
                                       Monitoring Stations
                                    </MenuItem>
                                 }
                                 {(!menuPermissions[Pages.MonitoringStation] && menuPermissions[Pages.Users]) &&
                                    <MenuItem id="/users" color="transparent">
                                       Users
                                    </MenuItem>
                                 }
                              </HeaderMenu>
                              <HeaderMenu label="Safe Trips" navigate={navigation}>
                                 {(menuPermissions[Pages.Trips]) &&
                                    <MenuItem id="/trips" color="transparent">
                                       Trips
                                    </MenuItem>
                                 }
                                 {(menuPermissions[Pages.Locations]) &&
                                    <MenuItem id="/triplocations" color="transparent">
                                       Trip Locations
                                    </MenuItem>
                                 }
                                 {(menuPermissions[Pages.HazardLocations]) &&
                                    <MenuItem id="/hazardlocations" color="transparent">
                                       Hazard Locations
                                    </MenuItem>
                                 }
                              </HeaderMenu>
                              <HeaderMenu label="Reports" navigate={navigation}>
                                 {(menuPermissions[Pages.EscalationReport]) &&
                                    <MenuItem id="/escalationreport" color="transparent">
                                       Escalation Report
                                    </MenuItem>
                                 }
                                 {(menuPermissions[Pages.DevicePersonReport]) &&
                                    <MenuItem id="/devicepersonreport" color="transparent">
                                       Device Person Report
                                    </MenuItem>
                                 }
                                 {(menuPermissions[Pages.DeviceTestReport]) &&
                                    <MenuItem id="/devicetests" color="transparent">
                                       Device Test Report
                                    </MenuItem>
                                 }
                                 {(menuPermissions[Pages.SftpImportLogs]) &&
                                    <MenuItem id="/SftpImportLog" color="transparent">
                                       SFTP Import Log
                                    </MenuItem>
                                 }
                                 {(menuPermissions[Pages.SftpMonitoringLogs]) &&
                                    <MenuItem id="/SftpMonitoringLog" color="transparent">
                                       SFTP Monitoring Log
                                    </MenuItem>
                                 }
                              </HeaderMenu>
                              <HeaderMenu label="Support" navigate={navigation}>
                                 {(menuPermissions[Pages.UserSupport]) &&
                                    <MenuItem id="/usersupport" color="transparent">
                                       User Support
                                    </MenuItem>
                                 }
                                 {(menuPermissions[Pages.ErrorLog]) &&
                                    <MenuItem id="/errorlog" color="transparent">
                                       Error Logs
                                    </MenuItem>
                                 }
                              </HeaderMenu>
                           </Box>
                        </div>
                        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                           GAS
                           {planName && planName !== "Intergalactic" &&
                              <span style={{ alignSelf: "center", color: "#c1932b", fontWeight: "bold", fontSize: "0.75em" }}>{planName}</span>
                           }
                        </Typography>
                        <Box sx={{ flexGrow: 0 }}>
                           {isAzureLogin &&
                              <div style={{ display: "flex" }}>
                                 {otherUserName &&
                                    <LogInAsUser className={classes.logInAsUser} userName={otherUserName} showClientApiKeys={menuPermissions[Pages.ClientApiKey] === true} />
                                 }
                                 <IconButton onClick={() => Logout()} sx={{ p: 0 }}>
                                    <ExitToAppRounded style={{ color: primaryColor[0] }} />
                                 </IconButton>
                              </div>
                           }
                           {!isAzureLogin &&
                              <div style={{ display: "flex" }}>
                                 {
                                    (Boolean(environment.kcEnabled) && otherUserName) ?
                                       <LogInAsUser className={classes.logInAsUser} userName={otherUserName} showClientApiKeys={menuPermissions[Pages.ClientApiKey] === true} />
                                       : <>
                                          <PopupState variant="popover" popupId="demo-popup-menu">
                                             {(popupState) => (
                                                <>
                                                   <IconButton {...bindTrigger(popupState)} sx={{ p: 0 }}>
                                                      <AccountCircle style={{ color: primaryColor[0] }} />
                                                   </IconButton>
                                                   <StyledMenu
                                                      id="demo-customized-menu"
                                                      MenuListProps={{
                                                         'aria-labelledby': 'demo-customized-button',
                                                      }}
                                                      {...bindMenu(popupState)}
                                                   >
                                                      <MenuItem key={"roles"} disableRipple disabled={true}>
                                                         <Accessibility style={{ color: primaryColor[0] }} />
                                                         {roles ?? ""}
                                                      </MenuItem>
                                                      <Divider />
                                                      <MenuItem key={"ChangePassword"} disableRipple onClick={() => { popupState.close(); setShowChangePassword(true) }}>
                                                         <Lock style={{ color: primaryColor[0] }} />
                                                         Change Password
                                                      </MenuItem>
                                                      {(menuPermissions[Pages.ClientApiKey]) &&
                                                         <>
                                                            <Divider />
                                                            <MenuItem component={Link} to="/apikeys" onClick={() => { popupState.close(); }}>
                                                               <Key style={{ color: primaryColor[0] }} />
                                                               Client Api Keys
                                                            </MenuItem>
                                                         </>
                                                      }
                                                      <Divider />
                                                      <MenuItem key={"LogOut"} disableRipple onClick={() => { popupState.close(); Logout() }}>
                                                         <ExitToApp style={{ color: primaryColor[0] }} />
                                                         Logout
                                                      </MenuItem>
                                                   </StyledMenu>
                                                </>
                                             )}
                                          </PopupState>
                                       </>
                                 }
                              </div>
                           }
                        </Box>
                        {((localStorage.getItem("IsApiFailing") === "true") || isApiFailing) ? (
                           <Link to="/errorlog">
                              <WarningSign text="Error while communication Monitoring Station, Either endpoint issue or credential changed." />
                           </Link>
                        ) : (
                           null
                        )}
                        {isGasStaff && ((localStorage.getItem("isWialonUnHealthy") === "true") || !isWialonWebhookHealthy) ? (
                           <Link to="/errorlog">
                              <WarningSign text="Error while communication Halo or Patriot instances." />
                           </Link>
                        ) : (
                           null
                        )}

                     </Toolbar>
                  </Container>
               </AppBar>
               {showChangePassword &&
                  <ChangePassword
                     open={showChangePassword}
                     close={() => setShowChangePassword(false)}
                     successCallback={() => setShowChangePassword(false)} />
               }
            </>
         }
      </>
   );
};

export default withStyles(Header, headerStyle);
