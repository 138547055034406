import { Controllers } from "../constants/constant";
import { IAssignDeviceToPerson, IDeleteParams, IPerson, IEscalation, IAssignDevicesToPerson, IResponseInstruction, ISearchableSelectParams } from "../types/dataTypes";
import request from "../utils/request";

class PersonService {

   searchPersons = (params: any) => {
      return request({
         url: `${Controllers.Person}/Search`,
         method: 'get',
         params
      });
   }

   savePerson = (data: IPerson) => {
      return request({
         url: `${Controllers.Person}`,
         method: 'post',
         data,
      })
   }

   deletePerson = (params: IDeleteParams) => {
      return request({
         url: `${Controllers.Person}`,
         method: 'delete',
         params
      })
   }

   getPersonSelectOptions = (clientOid: any, params: ISearchableSelectParams, signal: any) => {
      return request({
         url: `${Controllers.Person}/GetPersonSelectOptions/${clientOid}`,
         method: 'get',
         params,
         signal
      })
   }

   getPersonRelationshipToClientSelectOption = () => {
      return request({
         url: `${Controllers.Person}/GetPersonRelationOptions`,
         method: 'get',
      })
   }

   getClientSpecificPersonSelectOptions = (clientOid: string | undefined, deviceOid: string | undefined, deviceAssigned: boolean = false, params: any, signal: any) => {
      return request({
         url: `${Controllers.Person}/GetClientSpecificPersonSelectOptions/${clientOid}/${deviceOid}/${deviceAssigned}`,
         method: 'get',
         params,
         signal
      })
   }

   assignDeviceToPerson = (data: IAssignDeviceToPerson) => {
      return request({
         url: `${Controllers.Person}/AssignDeviceToPerson`,
         method: 'post',
         data,
      })
   }
   AssignMultipleDevicesToPerson = (data: IAssignDevicesToPerson) => {
      return request({
         url: `${Controllers.Person}/AssignMultipleDevicesToPerson`,
         method: 'post',
         data,
      })
   }
   getSearchSuggestions = (serachText: string, signal: any) => {
      return request({
         url: `${Controllers.Person}/SearchSuggestions/${serachText}`,
         method: 'get',
         signal
      })
   }

   import(data: FormData) {
      return request({
         url: `${Controllers.Person}/Import`,
         method: 'post',
         data,
         headers:
         {
            'Content-Type': 'multipart/form-data'
         }
      });
   }

   export(params: any) {
      return request({
         url: `${Controllers.Person}/Export`,
         method: 'get',
         params,
         responseType: 'blob',
      })
   }

   assignDefaultEscalation = (data: IEscalation, personOid?: string) => {
      return request({
         url: `${Controllers.Person}/AssignDefaultEscalation/${personOid}`,
         method: 'post',
         data,
      })
   }

   GetClientSpecificAccountOwnerSelectOptions = (clientOid: string | undefined, params: any, signal: any) => {
      return request({
         url: `${Controllers.Person}/GetClientSpecificAccountOwnerSelectOptions/${clientOid}`,
         method: 'get',
         params,
         signal
      })
   }

   GetClientReportRecipientSelectOptions = (clientOid: string | undefined, params: any, signal: any) => {
      return request({
         url: `${Controllers.Person}/GetClientReportRecipientSelectOptions/${clientOid}`,
         method: 'get',
         params,
         signal
      })
   }

   exportUploadFormat(params: any) {
      return request({
         url: `${Controllers.Person}/ExportTemplate`,
         method: 'get',
         responseType: 'blob',
         params
      });
   }

   getPersonDependencies = (params: IDeleteParams) => {
      return request({
         url: `${Controllers.Person}/GetPersonDependencies`,
         method: 'get',
         params
      })
   }
   setResponseInstruction = (data: IResponseInstruction, clientOid?: string) => {
      return request({
         url: `${Controllers.Person}/SetResponseInstruction/${clientOid}`,
         method: 'post',
         data,
      })
   }

   getPerson = (params: any) => {
      return request({
         url: `${Controllers.Person}`,
         method: 'get',
         params
      })
   }

   getPersonInfoByUser = (userOid: string) => {
      return request({
         url: `${Controllers.Person}/GetPersonInfoByUser/${userOid}`,
         method: 'get',
      })
   }

   getTimeZoneSelectOptions = () => {
      return request({
         url: `${Controllers.Person}/GetTimeZoneSelectOptions`,
         method: 'get',
      })
   }

   searchPersonLeaders = (params: any, signal: any) => {
      return request({
         url: `${Controllers.Person}/SearchPersonLeaders`,
         method: 'get',
         params,
         signal
      })
   }

   importDevicePersonMapping(data: FormData) {
      return request({
         url: `${Controllers.Person}/ImportDevicePersonMapping`,
         method: 'post',
         data,
         headers:
         {
            'Content-Type': 'multipart/form-data'
         }
      });
   }

   exportDevicePersonMappingUploadFormat(params: any) {
      return request({
         url: `${Controllers.Person}/ExportTemplateDevicePersonMapping`,
         method: 'get',
         responseType: 'blob',
         params
      });
   }

   getPersonForTripSelectOptions = (params: any, signal: any) => {
      return request({
         url: `${Controllers.Person}/GetPersonForTripSelectOptions`,
         method: 'get',
         params,
         signal
      })
   }

   getEscalationContactForTripSelectOptions = (params: any, signal: any) => {
      return request({
         url: `${Controllers.Person}/GetEscalationContactForTripSelectOptions`,
         method: 'get',
         params,
         signal
      })
   }

   getCountForPersonUsedInEscalations = (clientOid: string, params: any) => {
      return request({
         url: `${Controllers.Person}/GetCountForPersonUsedInEscalations/${clientOid}`,
         method: 'get',
         params
      })
   }

   findAndReplaceEscalationContact = (clientOid: string, data: any) => {
      return request({
         url: `${Controllers.Person}/FindAndReplaceEscalationContact/${clientOid}`,
         method: 'post',
         data
      })
   }

   getPersonCountByClientOid = (params: any) => {
      return request({
         url: `${Controllers.Person}/GetPersonCountByClientOid`,
         method: 'get',
         params,
      })
   }

   assignDefaultEscalationApplyAll = (data: any, clientOid?: string) => {
      return request({
         url: `${Controllers.Person}/AssignDefaultEscalationApplyAll/${clientOid}`,
         method: 'post',
         data,
      })
   }

   getFixedSelectOptions = () => {
      return request({
         url: `${Controllers.Person}/GetFixedSelectOptions`,
         method: 'get',
      })
   }

}

export default new PersonService();
