import Keycloak from 'keycloak-js';

let keycloak: any | null = null;

export function getKeycloakInstance(): any {
   if (!keycloak) {
      keycloak = new Keycloak(makeKeycloakConfig());
   }
   return keycloak;
}

export function makeKeycloakConfig() {
   const w = window as any;
   return {
      url: w?._env_?.KC_URL,
      realm: w?._env_?.KC_REALM,
      clientId: w?._env_?.KC_CLIENT_ID,
   };
}

export async function initKeycloak(onInitCallback: (authenticated: boolean) => void): Promise<void> {
   const keycloak = getKeycloakInstance();

   if (keycloak.authenticated !== undefined) {
      return; // Keycloak is already initialized
   }

   try {
      const authenticated = await keycloak.init({ onLoad: 'check-sso', checkLoginIframe: false });
      onInitCallback(authenticated);
   } catch (error) {
      console.error('Keycloak initialization error:', error);
   }
}

export default getKeycloakInstance();
