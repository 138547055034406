import { Suspense, useEffect, useState } from 'react';
import { MsalProvider } from "@azure/msal-react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { PrivateRoute } from './layouts/PrivateRoute';
import { Pages, StatusCode } from './constants/constant';
import LoginProcessing from "./Pages/LoginProcessing";
import Clients from './Pages/Clients/Clients';
import Devices from './Pages/Devices/Devices';
import Persons from './Pages/Persons/Persons';
import Search from './Pages/Search/Search';
import Alerts from './Pages/Alerts/Alerts';
import Users from './Pages/Users/Users';
import MonitoringStation from './Pages/MonitoringStation/MonitoringStation';
import Unauthorized from './Pages/Unauthorized';
import NotFound404 from './Pages/NotFound404';
import SessionExpired from './Pages/SessionExpired';
import Login from './Pages/Login/Login';
import ResetPassword from './Pages/ResetPassword/ResetPassword';
import EscalationDetail from './Pages/EscalationDetail/EscalationDetail';
import ClientApiKey from './Pages/ClientApiKey/ClientApiKey';
import EscalationReport from './Pages/Reports/EscalationReport';
import UserSupport from './Pages/UserSupport/UserSupport';
import ErrorLog from './Pages/ErrorLog/ErrorLog';
import DevicePersonReport from './Pages/Reports/DevicePersonReport';
import SftpImportLogs from './Pages/SftpImportLogs/SftpImportLogs';
import UnderMaintenance from './Pages/UnderMaintenance';
import Trips from './Pages/Trips/Trips';
import Locations from './Pages/Locations/Locations';
import TripDetail from './Pages/TripDetail/TripDetail';
import HazardLocations from './Pages/HazardLocations/HazardLocations';
import DeviceTestReport from './Pages/Reports/DeviceTestReport';
import SftpMonitoringLogs from './Pages/SftpMonitoringLogs/SftpMonitoringLogs';
import keycloak, { initKeycloak } from './utils/keycloak/keycloak';
import authService from './services/auth.service';
import environment from './environment';
import { msalInstance } from './utils/azureAuth/azureAuthProvider';
import LazyLoaderProgress from './components/Common/LazyLoaderProgress'; 
import { getHomePageForRole } from './utils/PermissionHelpers';
import { getLoggedInPortalRole } from './utils/LoginHelpers';

function App() {
   const [authenticated, setAuthenticated] = useState(false);
   const [loading, setLoading] = useState(true);
   const navigate = useNavigate();

   // This routing logic might need to be double checked. I set it to dynamically get the home page so it doesn't redirect client users to the 'clients' page if they don't have permissions. [Hashim, 17Dec24]
   const DynamicHomeRedirect = () => {
      const homeRoute = getHomePageForRole(getLoggedInPortalRole(false)) || "/clients";
      return <Navigate to={homeRoute} replace />;
   };
   
   useEffect(() => {
      if (environment.kcEnabled) {
         const handleKeycloakInit = async (authenticated: any) => {
            if (authenticated) {
               if (localStorage.getItem("permissions")) {
                  setAuthenticated(true);
               } else {
                  try {
                     const res = await authService.getFeaturesPermissions();
                     if (res?.status === StatusCode.Success && res?.data) {
                        console.log("loc 171224-1305:", res.data);
                        localStorage.setItem("permissions", JSON.stringify(res.data));
                     }
                     setAuthenticated(true);
                     let priviousURL = localStorage.getItem("PriviousURL");
                     if (priviousURL != "" && priviousURL != null) {
                        localStorage.setItem("PriviousURL", "");
                        navigate(priviousURL.toString());
                     } else {
                        const homeRoute = getHomePageForRole(getLoggedInPortalRole(false));
                        navigate(homeRoute || "/");
                     }
                  } catch (error) {
                     console.error('Error fetching permissions:', error);
                  }
               }
            } else {
               let currentURL = window.location.href;
               if (!currentURL.includes("/resetpassword") &&
                  !currentURL.includes("/setupaccount")) {
                  navigate("/login");
               }
            }
            setLoading(false);
         };

         initKeycloak(handleKeycloakInit);

         // Keycloak events
         const keycloakInstance = keycloak;
         keycloakInstance.onAuthSuccess = () => setAuthenticated(true);
         keycloakInstance.onAuthLogout = () => setAuthenticated(false);
         keycloakInstance.onTokenExpired = () => keycloakInstance.updateToken(30).catch(() => keycloakInstance.logout());
      }
   }, [navigate]);

   useEffect(() => {
      if (authenticated && window.location.pathname === '/') {
         const homeRoute = environment.kcEnabled 
            ? getHomePageForRole(getLoggedInPortalRole(false)) 
            : '/clients';
            
            navigate(homeRoute || '/');
      }
   }, [authenticated, navigate]);

   if (loading) {
      return <LazyLoaderProgress />; // Show loading spinner while initializing
   }

   return (
      <div>
         {(environment.kcEnabled) ?
                   <>
                   <Routes>
                     {(!authenticated) ? (
                        <Route path='/login' element={<Login />} />
                     ) : (
                        <>
                           <Route path="/" element={<DynamicHomeRedirect />} />
                           <Route path="/clients" >
                              <Route index element={<PrivateRoute page={Pages.Clients} component={Clients} />} />
                              <Route path="/clients/:searchText" element={<PrivateRoute page={Pages.Clients} component={Clients} />} />
                           </Route>
                           <Route path="/devices" >
                              <Route index element={<PrivateRoute page={Pages.Devices} component={Devices} />} />
                              <Route path="/devices/:searchText" element={<PrivateRoute page={Pages.Devices} component={Devices} />} />
                              <Route path="/devices/client/:clientOid" element={<PrivateRoute page={Pages.Devices} component={Devices} />} />
                           </Route>
                           <Route path="/persons" >
                              <Route index element={<PrivateRoute page={Pages.Persons} component={Persons} />} />
                              <Route path="/persons/:searchText" element={<PrivateRoute page={Pages.Persons} component={Persons} />} />
                           </Route>
                           <Route path="/search" element={<PrivateRoute page={Pages.Search} component={Search} />} />
                           <Route path="/alerts" element={<PrivateRoute page={Pages.Alerts} component={Alerts} />} />
                           <Route path="/users" element={<PrivateRoute page={Pages.Users} component={Users} />} />
                           <Route path="/users/:monitoringStationOid" element={<PrivateRoute page={Pages.Users} component={Users} />} />
                           <Route path="/monitoringstation" element={<PrivateRoute page={Pages.MonitoringStation} component={MonitoringStation} />} />
                           <Route path="/escalationdetail/:deviceid" element={<PrivateRoute page={Pages.Escalation} component={EscalationDetail} />} />
                           <Route path="/apikeys" element={<PrivateRoute page={Pages.ClientApiKey} component={ClientApiKey} />} />
                           <Route path="/escalationreport" element={<PrivateRoute page={Pages.EscalationReport} component={EscalationReport} />} />
                           <Route path="/usersupport" element={<PrivateRoute page={Pages.UserSupport} component={UserSupport} />} />
                           <Route path="/errorlog" element={<PrivateRoute page={Pages.ErrorLog} component={ErrorLog} />} />
                           <Route path="/devicepersonreport" element={<PrivateRoute page={Pages.DevicePersonReport} component={DevicePersonReport} />} />
                           <Route path="/SftpImportLog" element={<PrivateRoute page={Pages.SftpImportLogs} component={SftpImportLogs} />} />
                           <Route path="/trips" element={<PrivateRoute page={Pages.Trips} component={Trips} />} />
                           <Route path="/triplocations" element={<PrivateRoute page={Pages.Locations} component={Locations} />} />
                           <Route path="/tripdetail/:tripOid" element={<PrivateRoute page={Pages.TripDetail} component={TripDetail} />} />
                           <Route path="/tripdetail/edit/:tripOid" element={<PrivateRoute page={Pages.TripDetail} component={TripDetail} />} />
                           <Route path="/hazardlocations" element={<PrivateRoute page={Pages.HazardLocations} component={HazardLocations} />} />
                           <Route path="/devicetests" element={<PrivateRoute page={Pages.DeviceTestReport} component={DeviceTestReport} />} />
                           <Route path='/auth.html' element={<LoginProcessing />} />
                           <Route path="/SftpMonitoringLog" element={<PrivateRoute page={Pages.SftpMonitoringLogs} component={SftpMonitoringLogs} />} />
                        </>
                     )}
                     <Route path='/resetpassword/:linkid' element={<ResetPassword />} />
                     <Route path='/setupaccount/:linkid' element={<ResetPassword />} />
                     <Route path='/unauthorized' element={<Unauthorized />} />
                     <Route path='/sessionexpired' element={<SessionExpired />} />
                     <Route path='/undermaintenance' element={<UnderMaintenance />} />
                     <Route path='/networkissue' element={<UnderMaintenance />} />
                     <Route path='*' element={<NotFound404 />} />
                                  </Routes>
                                  </>
             :
            <MsalProvider instance={msalInstance}>
               <Suspense fallback={<LazyLoaderProgress />}>
                  <Routes>
                     <Route path='/resetpassword/:linkid' element={<ResetPassword />} />
                     <Route path='/setupaccount/:linkid' element={<ResetPassword />} />
                     <Route path='/login' element={<Login />} />
                     <Route path='/unauthorized' element={<Unauthorized />} />
                     <Route path='/sessionexpired' element={<SessionExpired />} />
                     <Route path="/clients" >
                        <Route index element={<PrivateRoute page={Pages.Clients} component={Clients} />} />
                        <Route path="/clients/:searchText" element={<PrivateRoute page={Pages.Clients} component={Clients} />} />
                     </Route>
                     <Route path="/devices" >
                        <Route index element={<PrivateRoute page={Pages.Devices} component={Devices} />} />
                        <Route path="/devices/:searchText" element={<PrivateRoute page={Pages.Devices} component={Devices} />} />
                        <Route path="/devices/client/:clientOid" element={<PrivateRoute page={Pages.Devices} component={Devices} />} />
                     </Route>
                     <Route path="/persons" >
                        <Route index element={<PrivateRoute page={Pages.Persons} component={Persons} />} />
                        <Route path="/persons/:searchText" element={<PrivateRoute page={Pages.Persons} component={Persons} />} />
                     </Route>
                     <Route path="/search" element={<PrivateRoute page={Pages.Search} component={Search} />} />
                     <Route path="/alerts" element={<PrivateRoute page={Pages.Alerts} component={Alerts} />} />
                     <Route path="/users" element={<PrivateRoute page={Pages.Users} component={Users} />} />
                     <Route path="/users/:monitoringStationOid" element={<PrivateRoute page={Pages.Users} component={Users} />} />
                     <Route path="/monitoringstation" element={<PrivateRoute page={Pages.MonitoringStation} component={MonitoringStation} />} />
                     <Route path="/escalationdetail/:deviceid" element={<PrivateRoute page={Pages.Escalation} component={EscalationDetail} />} />
                     <Route path="/apikeys" element={<PrivateRoute page={Pages.ClientApiKey} component={ClientApiKey} />} />
                     <Route path="/escalationreport" element={<PrivateRoute page={Pages.EscalationReport} component={EscalationReport} />} />
                     <Route path="/usersupport" element={<PrivateRoute page={Pages.UserSupport} component={UserSupport} />} />
                     <Route path="/errorlog" element={<PrivateRoute page={Pages.ErrorLog} component={ErrorLog} />} />
                     <Route path="/devicepersonreport" element={<PrivateRoute page={Pages.DevicePersonReport} component={DevicePersonReport} />} />
                     <Route path="/SftpImportLog" element={<PrivateRoute page={Pages.SftpImportLogs} component={SftpImportLogs} />} />
                     <Route path="/trips" element={<PrivateRoute page={Pages.Trips} component={Trips} />} />
                     <Route path="/triplocations" element={<PrivateRoute page={Pages.Locations} component={Locations} />} />
                     <Route path="/tripdetail/:tripOid" element={<PrivateRoute page={Pages.TripDetail} component={TripDetail} />} />
                     <Route path="/tripdetail/edit/:tripOid" element={<PrivateRoute page={Pages.TripDetail} component={TripDetail} />} />
                     <Route path="/hazardlocations" element={<PrivateRoute page={Pages.HazardLocations} component={HazardLocations} />} />
                     <Route path="/devicetests" element={<PrivateRoute page={Pages.DeviceTestReport} component={DeviceTestReport} />} />
                     <Route path='/undermaintenance' element={<UnderMaintenance />} />
                     <Route path='/networkissue' element={<UnderMaintenance />} />
                     <Route path='/auth.html' element={<LoginProcessing />} />
                     <Route path="/SftpMonitoringLog" element={<PrivateRoute page={Pages.SftpMonitoringLogs} component={SftpMonitoringLogs} />} />
                     <Route path='*' element={<NotFound404 />} />
                  </Routes>
               </Suspense>
            </MsalProvider>
         }
      </div>
   );
}

export default App;
